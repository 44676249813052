// PrettyyText.jsx
import React from 'react';
import { Text, StyleSheet } from 'react-native';
import { MotiText } from 'moti';

const styles = StyleSheet.create({
    defaultStyle: {
        fontFamily: 'dmsans',
    },
});

const PrettyyText = function ({
    children,
    className = '',
    style = {},
    ...props
}) {
    return (
        <MotiText
            className={`${className}`}
            style={[styles.defaultStyle, style]}
            {...props}
            transition={{
                type: 'timing',
            }}
        >
            {children}
        </MotiText>
    );
}

export default PrettyyText;
