import React from 'react';
import { Text, TextInput, TouchableOpacity, View, Button, SafeAreaView } from "react-native";
import { SparklesIcon } from "react-native-heroicons/solid";

import { useColorScheme } from 'nativewind';

import PrettyyText from '../../extensions/PrettyyText';

export default function NotFound({ }) {

  // Use color scheme hook to toggle between light and dark mode.
  const { colorScheme, toggleColorScheme } = useColorScheme();

  return (
    <SafeAreaView className="w-full h-96">
      <View className="p-4 lg:p-12 mx-auto flex justify-center items-center">
        <PrettyyText className="text-2xl font-bold text-black dark:text-white">
            404
        </PrettyyText>
        <PrettyyText className="text-2xl font-bold text-black dark:text-white">
            Page not found
        </PrettyyText>
      </View>
    </SafeAreaView>
  );
}