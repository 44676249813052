import React, { useEffect, useState, useReducer } from 'react';
import { Text, TextInput, View, Button, SafeAreaView, Pressable, StyleSheet, Platform, useWindowDimensions } from "react-native";
import { SparklesIcon } from "react-native-heroicons/solid";

import { useColorScheme, styled } from 'nativewind';

import PrettyyText from '../../../extensions/PrettyyText';

import {
  LogOut,
  MoonSat,
  SunLight,
  Menu
} from 'iconoir-react-native';

const LogOutIcon = styled(LogOut)
const MoonSatIcon = styled(MoonSat)
const SunLightIcon = styled(SunLight)
const MenuIcon = styled(Menu)

import { useAuth } from '../../../auth/AuthProvider';
import { tokenCache } from "../../../auth/TokenCache";

// Animations
import { Skeleton } from 'moti/skeleton';
import { MotiView } from 'moti';

// Copy to Clipboard
import * as Clipboard from 'expo-clipboard';

// Image
import { Image } from 'expo-image';
import useApi from '../../../api/useApi';

/*
NOTES:

- If the user has requested another user's profile, then that profile should be displayed with a back button to return to the previous page
- If there was no previous page (i.e. the user clicked on a link to the profile), then the back button should return the user to the homepage

*/

export default function Profile({ user = '' }) {
  const [username, setUsername] = useState(user);
  const [profileImg, setProfileImg] = useState(null);
  const [name, setName] = useState(null);
  const [bio, setBio] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userCanEdit, setUserCanEdit] = useState(false);

  const { signOut } = useAuth();

  const { width } = useWindowDimensions();
  const isDesktop = width >= 1264;

  let user_to_request = username;

  const { get } = useApi();

  async function getUserDetails(user_to_retrieve = username) {
    let currentUser = await tokenCache.getToken('username');

    if (username === '' || username === null) {
      setUsername(currentUser);
      user_to_request = currentUser;
      //console.warn('No username provided, using current user\'s username')
    }

    if (currentUser === user) {
      // this user is looking at their profile
      setUserCanEdit(true);
    }

    // get user details
    let userDetails = await get(`/v1/users/profile/${user_to_retrieve}`);

    if (userDetails?.error && userDetails?.error?.includes('User not found')) {
      // user does not exist
      setError('User not found');
      return;
    }

    setName(userDetails?.full_name);
    setUsername(userDetails?.username);
    setBio(userDetails?.bio);
    setProfileImg(userDetails?.avatar);

    setLoading(false);
  }

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    setLoading(true);
    setUsername(user);
    setError(null);

    getUserDetails(user); 
  }, [user]);

  const copyToClipboard = async (text) => {
    await Clipboard.setStringAsync(text);
    // Optionally display an alert or some other form of feedback
    //setLoading(!loading) // FIXME: Remove this line
    //alert(`Username copied to clipboard!`);
  };

  // Use color scheme hook to toggle between light and dark mode.
  const { colorScheme, toggleColorScheme } = useColorScheme();

  return (
    <SafeAreaView className="w-full flex-1">
      {(error && error?.includes('User not found')) ? (
        <>
          <MotiView
            className="w-full border-b-2 h-[52px]"
            animate={{
              borderColor: colorScheme === 'dark' ? (
                'rgba(23, 23, 23, 0.75)'
              ) : (
                'rgba(229, 229, 229, 0.5)'
              ),
              backgroundColor: colorScheme === 'dark' ? (
                'rgba(0, 0, 0, 1)'
              ) : (
                'rgba(250, 250, 250, 1)'
              ),
            }}
          >
            <View className="flex items-center justify-center flex-1">
              <View className="flex items-center justify-center">
                <PrettyyText className="text-base font-bold text-center cursor-pointer"
                  animate={{
                    color: colorScheme === 'dark' ? (
                      'rgba(255, 255, 255, 1)'
                    ) : (
                      'rgba(0, 0, 0, 1)'
                    ),
                  }}
                >
                  @{user_to_request}
                </PrettyyText>
              </View>
            </View>
          </MotiView>
          <View className="mx-auto flex-1 p-12">
            <View className="flex items-center justify-center">
              <MotiView
                className="border rounded-full w-[125px] h-[125px]"
                animate={{
                  borderColor: colorScheme === 'dark' ? (
                    'rgba(64, 64, 64, 1)'
                  ) : (
                    'rgba(229, 229, 229, 1)'
                  ),
                }}
                transition={{ type: 'timing', duration: 300 }}
              />
            </View>
            <View className="mt-2 flex justify-center items-center">
              <PrettyyText className="text-2xl font-bold text-center cursor-pointer"
                animate={{
                  color: colorScheme === 'dark' ? (
                    'rgba(255, 255, 255, 1)'
                  ) : (
                    'rgba(0, 0, 0, 1)'
                  ),
                }}
              >
                User not found
              </PrettyyText>
            </View>
            <View className="mt-2 flex justify-center items-center">
              <PrettyyText className="text-base text-center max-w-md"
                animate={{
                  color: colorScheme === 'dark' ? (
                    'rgba(180, 180, 180, 1)'
                  ) : (
                    'rgba(23, 23, 23, 1)'
                  ),
                }}
              >
                Woah, are you a time machine? No one’s claimed this username yet!
              </PrettyyText>
            </View>
          </View>
        </>
      ) : (
        <>
          {/* Banner */}
          <MotiView
            className="w-full border-b-2"
            animate={{
              borderColor: colorScheme === 'dark' ? (
                'rgba(23, 23, 23, 0.75)'
              ) : (
                'rgba(229, 229, 229, 0.5)'
              ),
              backgroundColor: colorScheme === 'dark' ? (
                'rgba(0, 0, 0, 1)'
              ) : (
                'rgba(245, 245, 245, 1)'
              ),
            }}
          >
            <Skeleton.Group show={loading}>
              <View className="flex items-center justify-center flex-1 w-full">
                <Skeleton
                  colorMode={colorScheme}
                  height={52}
                  radius={0}
                  width={'100%'}
                  transition={{ type: 'timing', duration: 1000 }}
                >
                  <MotiView
                    animate={{
                      opacity: loading ? 0 : 1,
                    }}
                    transition={{
                      type: 'timing',
                      duration: 1000,
                    }}
                    className={`flex items-center justify-center h-full ${(Platform.OS === 'ios' || Platform.OS === 'android') ? ('w-screen') : ('w-full')}`} // ml-[115px] resolves the issue on iOS, but I can't figure out a way to switch this to responsive
                  >
                    <View className="flex-row items-center justify-between w-full h-full mx-auto">
                      {!isDesktop ? (
                        <View className="flex items-center justify-center">
                          <Pressable onPress={() => toggleColorScheme()}>
                            <Skeleton
                              colorMode={colorScheme}
                              height={52}
                              radius={0}
                              width={52}
                              transition={{ type: 'timing', duration: 1000 }}
                            >
                              <MotiView
                                animate={{
                                  opacity: loading ? 0 : 1,
                                  color: colorScheme === 'dark' ? (
                                    'rgba(255, 255, 255, 1)'
                                  ) : (
                                    'rgba(0, 0, 0, 1)'
                                  ),
                                }}
                                transition={{
                                  type: 'timing',
                                  duration: 1000,
                                }}
                                className="flex items-center justify-center w-full h-[52px]"
                              >
                                {colorScheme === 'dark' ? (
                                  <SunLight
                                    width={24}
                                    height={24}
                                    color={colorScheme === 'dark' ? (
                                      'rgba(255, 255, 255, 1)'
                                    ) : (
                                      'rgba(0, 0, 0, 1)'
                                    )}
                                  />
                                ) : (
                                  <MoonSat
                                    width={24}
                                    height={24}
                                    color={colorScheme === 'dark' ? (
                                      'rgba(255, 255, 255, 1)'
                                    ) : (
                                      'rgba(0, 0, 0, 1)'
                                    )}
                                  />
                                )}
                              </MotiView>
                            </Skeleton>
                          </Pressable>
                        </View>
                      ) : (
                        <View>

                        </View>
                      )}
                      <View className="flex items-center justify-center">
                        <Pressable onPress={() => copyToClipboard('@' + username)}>
                          <PrettyyText className="text-base font-bold text-center cursor-pointer"
                            animate={{
                              color: colorScheme === 'dark' ? (
                                'rgba(255, 255, 255, 1)'
                              ) : (
                                'rgba(0, 0, 0, 1)'
                              ),
                            }}
                          >
                            @{user_to_request}
                          </PrettyyText>
                        </Pressable>
                      </View>
                      {!isDesktop ? (
                        <View className="flex items-center justify-center">
                          <Pressable onPress={() => signOut()}>
                            <Skeleton
                              colorMode={colorScheme}
                              height={52}
                              radius={0}
                              width={52}
                              transition={{ type: 'timing', duration: 1000 }}
                            >
                              <MotiView
                                animate={{
                                  opacity: loading ? 0 : 1,
                                  color: colorScheme === 'dark' ? (
                                    'rgba(255, 255, 255, 1)'
                                  ) : (
                                    'rgba(0, 0, 0, 1)'
                                  ),
                                }}
                                transition={{
                                  type: 'timing',
                                  duration: 1000,
                                }}
                                className="flex items-center justify-center w-full h-[52px]"
                              >
                                {/*<MenuIcon
                                width={24}
                                height={24}
                                color={colorScheme === 'dark' ? (
                                  'rgba(255, 255, 255, 1)'
                                ) : (
                                  'rgba(0, 0, 0, 1)'
                                )}
                              />*/}
                                <LogOutIcon
                                  width={24}
                                  height={24}
                                  color={colorScheme === 'dark' ? (
                                    'rgba(255, 255, 255, 1)'
                                  ) : (
                                    'rgba(0, 0, 0, 1)'
                                  )}
                                />
                              </MotiView>
                            </Skeleton>
                          </Pressable>
                        </View>
                      ) : (
                        <View>

                        </View>
                      )}
                    </View>
                  </MotiView>
                </Skeleton>
              </View>
            </Skeleton.Group>
          </MotiView>
          <View className="mx-auto flex-1 mt-12 w-full">
            <Skeleton.Group show={loading}>
              {/* Profile Image */}
              <View className="flex items-center justify-center">
                <MotiView
                  className="border rounded-full"
                  animate={{
                    borderColor: colorScheme === 'dark' ? (
                      'rgba(64, 64, 64, 1)'
                    ) : (
                      'rgba(229, 229, 229, 1)'
                    ),
                  }}
                  transition={{ type: 'timing', duration: 300 }}
                >
                  <Skeleton
                    colorMode={colorScheme}
                    radius="round"
                    height={125}
                    width={125}
                    transition={{ type: 'timing', duration: 1000 }}
                  >
                    <MotiView
                      // animate opacity from 0 to 1 (and back) depending on `loading`
                      animate={{
                        opacity: loading ? 0 : 1,
                      }}
                      // transition animation config
                      transition={{
                        type: 'timing',
                        duration: 1000,
                      }}
                      className="rounded-full w-[125px] h-[125px]"
                    >
                      <Image
                        source={profileImg}
                        contentFit="cover"
                        //transition={1000}
                        className="rounded-full w-[125px] h-[125px]"
                      />
                    </MotiView>
                  </Skeleton>
                </MotiView>
              </View>
              {/* Name */}
              <View className="mt-2 flex justify-center items-center">
                <Skeleton
                  colorMode={colorScheme}
                  height={32}
                  width={250}
                  transition={{ type: 'timing', duration: 1000 }}
                >
                  <MotiView
                    // animate opacity from 0 to 1 (and back) depending on `loading`
                    animate={{
                      opacity: loading ? 0 : 1,
                    }}
                    // transition animation config
                    transition={{
                      type: 'timing',
                      duration: 1000,
                    }}
                  >
                    <PrettyyText className="text-2xl font-bold text-center cursor-pointer"
                      animate={{
                        color: colorScheme === 'dark' ? (
                          'rgba(255, 255, 255, 1)'
                        ) : (
                          'rgba(0, 0, 0, 1)'
                        ),
                      }}
                    >
                      {name}
                    </PrettyyText>
                  </MotiView>
                </Skeleton>
              </View>

              {/* User Bio */}
              <View className="mt-2 flex justify-center items-center w-full mx-auto max-w-md">
                <Skeleton
                  colorMode={colorScheme}
                  height={64}
                  width={448}
                  transition={{ type: 'timing', duration: 1000 }}
                >
                  <MotiView
                    // animate opacity from 0 to 1 (and back) depending on `loading`
                    animate={{
                      opacity: loading ? 0 : 1,
                    }}
                    // transition animation config
                    transition={{
                      type: 'timing',
                      duration: 1000,
                    }}
                  >
                    <PrettyyText className="text-base text-center max-w-[300px] sm:max-w-md mx-auto"
                      animate={{
                        color: colorScheme === 'dark' ? (
                          'rgba(180, 180, 180, 1)'
                        ) : (
                          'rgba(23, 23, 23, 1)'
                        ),
                      }}
                    >
                      {bio}
                    </PrettyyText>
                  </MotiView>
                </Skeleton>
              </View>

            </Skeleton.Group>
          </View>
        </>
      )}
    </SafeAreaView>
  );
}