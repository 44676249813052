import React, { useEffect } from 'react';
import { useWindowDimensions, View, ScrollView, Pressable, Text, SafeAreaView, Platform } from "react-native";
import * as Linking from 'expo-linking';

import PrettyyText from '../../extensions/PrettyyText';

import { useColorScheme } from "nativewind";
import {
  MoonSat,
  SunLight,
  LogOut
} from 'iconoir-react-native';

import { MotiView } from 'moti';

import { useAuth } from '../../auth/AuthProvider';
import { tokenCache } from "../../auth/TokenCache";

export default function Display({ children, tabs, currentPage, setCurrentPage, setRequestedUser = () => { } }) {
  const [username, setUsername] = React.useState('');

  const { width } = useWindowDimensions();

  const { signOut } = useAuth();

  // Adjust the breakpoint as needed.
  const isMobile = width < 600;
  const isTablet = width >= 600 && width < 1264;
  const isDesktop = width >= 1264;

  // Use color scheme hook to toggle between light and dark mode.
  const { colorScheme, toggleColorScheme } = useColorScheme();

  const mobileFix = (Platform.OS === 'ios' && isMobile) ? ' h-20' : 'h-16';

  useEffect(() => {
    async function getUserDetails() {
      setUsername(await tokenCache.getToken('username'));
    }

    getUserDetails();
  }, []);

  return (
    <MotiView
      transition={{
        type: 'timing',
      }}
      className={`${isMobile ? '' : isTablet ? 'ml-16' : 'ml-64'} flex-1`}
      animate={{
        backgroundColor: colorScheme === 'dark' ? (
          'rgba(10, 10, 10, 1)'
        ) : (
          'rgba(245, 245, 245, 1)'
        ),
      }}
    >
      <View className={`flex-1 overflow-hidden`}>
        {/* Main content */}
        <View className={`flex-1`}>
          <MotiView
            transition={{
              type: 'timing',
            }}
            className={`z-0 flex-1`}
            animate={{
              backgroundColor: colorScheme === 'dark' ? (
                'rgba(23, 23, 23, 1)'
              ) : (
                'rgba(245, 245, 245, 1)'
              ),
            }}
          >
            <ScrollView>
              {children}
            </ScrollView>
          </MotiView>
        </View>

        {/* Sidebar/Navigation */}
        <MotiView
          className={
            `flex border-0 z-10 ${mobileFix} ${isMobile
              ? 'bottom-0 w-full justify-center items-center border-t-2'
              : isTablet
                ? 'top-0 left-0 w-16 h-full justify-center items-center border-r-2'
                : 'top-0 left-0 w-64 h-full justify-start items-start border-r-2'
            }`
          }
          animate={{
            backgroundColor: colorScheme === 'dark' ? (
              'rgba(0, 0, 0, 1)'
            ) : (
              'rgba(245, 245, 245, 1)'
            ),
            borderColor: colorScheme === 'dark' ? (
              'rgba(23, 23, 23, 0.75)'
            ) : (
              'rgba(229, 229, 229, 0.5)'
            ),
          }}
          style={{
            position: 'fixed',
          }}
        >
          <View className={`${isMobile ? 'flex-row justify-evenly' : 'flex-col justify-start space-y-2'} w-full items-center ${!isMobile && ('mt-2 px-4 pt-4')}`}>
            {tabs.map((tab) => (
              <Pressable
                key={tab.uid}
                onPress={() => {
                  if (tab.uid === 'profile') {
                    if (Platform.OS === 'web') {
                      Linking.openURL(`/@${username}`).catch(err => (err));
                    } else {
                      setCurrentPage(tab.uid);
                      setRequestedUser(username);
                    }
                  } else {
                    if (currentPage === "profile") {
                      if (Platform.OS === 'web') {
                        Linking.openURL(`/#/${tab.uid}`).catch(err => (err));
                      } else {
                        setCurrentPage(tab.uid);
                      }
                    } else {
                      setCurrentPage(tab.uid);
                    }
                  }
                }}
                className={`flex ${isMobile || isTablet ? 'justify-center w-12' : 'w-full justify-start'} items-center ${isDesktop ? 'space-x-2' : ''} h-12 rounded-2xl overflow-hidden bg-transparent`}
              >
                <MotiView
                  className={`w-full h-12 ${isDesktop ? 'flex-row p-2' : 'flex-col justify-center'} items-center`}
                  animate={{
                    backgroundColor: tab.uid === currentPage ? (
                      colorScheme === 'dark' ? (
                        'rgba(23, 23, 23, 1)'
                      ) : (
                        'rgba(235, 235, 235, 1)'
                      )
                    ) : (
                      colorScheme === 'dark' ? (
                        'rgba(0, 0, 0, 1)'
                      ) : (
                        'rgba(245, 245, 245, 1)'
                      )
                    ),
                  }}
                >
                  <MotiView
                    animate={{
                      color: colorScheme === 'dark' ? '#fff' : '#000',
                    }}
                    transition={{
                      type: 'timing',
                    }}
                    className={`${isDesktop ? 'ml-1 mr-3' : ''} flex justify-center items-center`}
                  >
                    <tab.icon width={24} height={24} color={colorScheme === 'dark' ? '#fff' : '#000'} />
                  </MotiView>
                  {isDesktop && (
                    <PrettyyText
                      className="text-base"
                      animate={{
                        color: colorScheme === 'dark' ? (
                          'rgba(255, 255, 255, 1)'
                        ) : (
                          'rgba(0, 0, 0, 1)'
                        ),
                      }}>
                      {tab.name}
                    </PrettyyText>
                  )}
                </MotiView>
              </Pressable>
            ))}
          </View>

          {!isMobile && (
            <>
              <MotiView
                className={`flex flex-col items-center justify-center w-full mt-auto mb-2`}
                animate={{
                  backgroundColor: colorScheme === 'dark' ? (
                    'rgba(23, 23, 23, 0.5)'
                  ) : (
                    'rgba(229, 229, 229, 0.25)'
                  ),
                }}
              >
                <Pressable
                  onPress={toggleColorScheme}
                  className={`flex items-center justify-center px-3 py-2 w-full`}
                >
                  <View className={`w-12 h-12 flex justify-center items-center`}>
                    <MotiView
                      animate={{
                        opacity: colorScheme === 'dark' ? 0 : 1,
                        color: colorScheme === 'dark' ? '#fff' : '#000',
                      }}
                      transition={{ type: 'timing', duration: 300 }}
                    >
                      <MoonSat className={colorScheme === 'dark' ? 'hidden' : ''} />
                    </MotiView>
                    <MotiView
                      animate={{
                        opacity: colorScheme === 'dark' ? 1 : 0,
                        color: colorScheme === 'dark' ? '#fff' : '#000',
                      }}
                      transition={{ type: 'timing', duration: 300 }}
                    >
                      <SunLight className={colorScheme === 'dark' ? '' : 'hidden'} />
                    </MotiView>
                  </View>
                </Pressable>
              </MotiView>
              <MotiView
                className={`flex flex-col items-center justify-center w-full mb-2`}
                animate={{
                  backgroundColor: colorScheme === 'dark' ? (
                    'rgba(23, 23, 23, 0.5)'
                  ) : (
                    'rgba(229, 229, 229, 0.25)'
                  ),
                }}
              >
                <Pressable
                  onPress={signOut}
                  className={`flex items-center justify-center px-3 py-2 w-full`}
                >
                  <MotiView
                    animate={{
                      color: colorScheme === 'dark' ? '#fff' : '#000',
                    }}
                    transition={{
                      type: 'timing',
                    }}
                    className={`w-12 h-12 flex justify-center items-center`}
                  >
                    <LogOut className="" />
                  </MotiView>
                </Pressable>
              </MotiView>
            </>
          )}
        </MotiView>
      </View>
    </MotiView>
  );
}
