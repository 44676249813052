import React, { useEffect } from "react";
import { SafeAreaView, Text, StyleSheet, View, Button, Pressable } from "react-native";

// Linking
import * as Linking from 'expo-linking';
import useHashChange from "./hooks/useHashChange";

// Display / Navigation
import Display from "./components/navigation/Display";

// Page Components
import Homepage from "./components/app/HomeSection/Homepage";
import SearchComponent from "./components/app/Search";
import Explore from "./components/app/Explore";
import Profile from "./components/app/ProfileSection/Profile";
import NotFound from "./components/app/NotFound";

// Authentication
import { AuthProvider, IsSignedIn, IsSignedOut, useAuth } from "./auth/AuthProvider";
import { AuthScreen, useAuthScreen } from "./auth/AuthScreens";

// Animations
import 'react-native-reanimated'
import 'react-native-gesture-handler'
import { MotiView } from 'moti';

import {
  MoonSat,
  SunLight
} from 'iconoir-react-native';

import { tokenCache } from "./auth/TokenCache";

const MoonSatIcon = styled(MoonSat);
const SunLightIcon = styled(SunLight);

// Animation Fix
import { Platform } from 'react-native'
if (Platform.OS === 'web') {
  global._frameTimestamp = null
}

// Styles
import { NativeWindStyleSheet, styled, useColorScheme } from 'nativewind';

// TailwindCSS Bug Fix:
NativeWindStyleSheet.setOutput({
  default: "native",
});

// Icons
import {
  HomeSimple,
  Search,
  Compass,
  UserCircle
} from 'iconoir-react-native';

const HomeSimpleIcon = styled(HomeSimple);
const SearchIcon = styled(Search);
const CompassIcon = styled(Compass);
const UserCircleIcon = styled(UserCircle);

const tabs = [
  {
    name: 'Home',
    uid: 'home',
    component: Homepage,
    icon: HomeSimpleIcon,
    isMobile: true,
  },
  {
    name: 'Search',
    uid: 'search',
    component: SearchComponent,
    icon: SearchIcon,
    isMobile: true,
  },
  {
    name: 'Explore',
    uid: 'explore',
    component: Explore,
    icon: CompassIcon,
    isMobile: false,
  },
  {
    name: 'Profile',
    uid: 'profile',
    component: Profile,
    icon: UserCircleIcon,
    isMobile: true,
  }
]

// Fonts
import useFonts from './hooks/useFonts';
import SplashScreen from "./components/SplashScreen";
import PrettyyText from "./extensions/PrettyyText";

export default function App() {
  const [currentPage, setCurrentPage] = React.useState('');
  const [fontLoaded, setFontLoaded] = React.useState(false);
  const [requestedUser, setRequestedUser] = React.useState(null);

  const url = Linking.useURL();
  const currentHash = useHashChange();
  const wasProgrammaticChange = React.useRef(false);

  // Use color scheme hook to toggle between light and dark mode.
  const { colorScheme, setColorScheme } = useColorScheme();

  // Effect for loading the initial color scheme
  React.useEffect(() => {
    async function loadAndSetColourScheme() {
      let savedColourScheme = await tokenCache.getToken('colorScheme');

      // If there is no saved color scheme, default to 'light'
      if (!savedColourScheme) {
        savedColourScheme = 'light';
        await tokenCache.saveToken('colorScheme', savedColourScheme);
      }

      // Set the loaded color scheme without toggling
      setColorScheme(savedColourScheme);
    }

    loadAndSetColourScheme();
  }, [setColorScheme]);

  // Effect for saving the color scheme whenever it changes
  React.useEffect(() => {
    async function saveColourScheme() {
      await tokenCache.saveToken('colorScheme', colorScheme);
    }

    // Avoid saving the initial state (right after loading)
    if (colorScheme !== undefined) {
      saveColourScheme();
    }
  }, [colorScheme]);

  React.useEffect(() => {
    async function loadFonts() {
      await useFonts();
      setFontLoaded(true);
    }
    loadFonts();
  }, []);

  React.useEffect(() => {
    if (wasProgrammaticChange.current) {
      wasProgrammaticChange.current = false;
      return;
    }

    if (url) {
      const matchUsername = url.match(/\/@([^\/]+)$/);
      const matchPath = url.match(/\/([^\/]+)$/);

      if (matchUsername) {
        const username = matchUsername[1];
        setRequestedUser(username);
        setCurrentPage('profile');
      } else if (matchPath) {
        const path = matchPath[1];
        // split the path by /
        const pathParts = path.split('/');
        // if the first path part is in the tabs, set the current page to that, otherwise set it to home
        if (tabs.find((tab) => tab.uid === pathParts[0])) {
          setCurrentPage(pathParts[0]);
        } else {
          setCurrentPage('home');
        }
      } else {
        setCurrentPage('home');
      }
    }

    if (currentHash) {
      const matchPath = currentHash.match(/^#\/(.+)$/);
      if (matchPath && matchPath[1] && matchPath[1] !== currentPage && matchPath[1] !== 'profile') {
        const path = matchPath[1];
        setCurrentPage(path);
      }
    }

    // Fail-safe, if the current page is empty, set it to home
    if (currentPage === '' && Platform.OS !== 'web') {
      setCurrentPage('home');
    }
  }, [url, currentHash]);

  React.useEffect(() => {
    if (currentPage !== '' && currentPage !== 'profile') {
      wasProgrammaticChange.current = true;
      Linking.openURL(`/#/${currentPage}`).catch(err => (err));
    }
  }, [currentPage]);

  if (!fontLoaded) {
    return <SplashScreen />;
  }

  return (
    <AuthProvider>
      <ContentComponent setCurrentPage={setCurrentPage} currentPage={currentPage} requestedUser={requestedUser} setRequestedUser={setRequestedUser} />
    </AuthProvider>
  );
}

function ContentComponent({ setCurrentPage, currentPage, requestedUser = null, setRequestedUser = () => { } }) {
  const currentView = () => {
    const Component = tabs?.find((tab) => tab.uid === currentPage)?.component;
    return Component ? <Component user={requestedUser} setRequestedUser={setRequestedUser} /> : <NotFound />;
  };

  const { colorScheme, toggleColorScheme } = useColorScheme();

  useEffect(() => {
    // if requestedUser changes, but is not null, set the current page to profile
    if (requestedUser) {
      setCurrentPage('profile');
    }
  }, [requestedUser]);

  useEffect(() => {
    // if the currentPage changes, but is not profile, set the requestedUser to null
    if (currentPage !== 'profile') {
      setRequestedUser(null);
    }
  }, [currentPage]);

  return (
    <>
      <MotiView
        transition={{
          type: 'timing',
        }}
        className="flex-1"
        animate={{
          backgroundColor: colorScheme === 'dark' ? 'black' : 'white',
        }}
      >
        <IsSignedIn>
          <View className="flex-1">
            <View className="flex-1">
              <Display type={Platform.OS} tabs={tabs} setCurrentPage={setCurrentPage} currentPage={currentPage} setRequestedUser={setRequestedUser}>
                {currentView()}
                {/* 
                
                IMPORTANT NOTE:
                If the a verify request fails due to a network error, it informs the user they
                are using expired/invalid credentials - this is not acceptable.
                
                */}
              </Display>
            </View>
          </View>
        </IsSignedIn>
        <IsSignedOut>
          <View className="flex-1 flex justify-center items-center">
            <AuthScreen />
          </View>
        </IsSignedOut>
      </MotiView>
    </>
  );
}