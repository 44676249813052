import React from 'react';
import { Text, TextInput, TouchableOpacity, View, Button, SafeAreaView } from "react-native";
import { SparklesIcon } from "react-native-heroicons/solid";

import { useColorScheme } from 'nativewind';

import PrettyyText from '../../../extensions/PrettyyText';

import HomeNavigation from './HomeNavigation';

export default function Homepage({ }) {

  // Use color scheme hook to toggle between light and dark mode.
  const { colorScheme, toggleColorScheme } = useColorScheme();

  return (
    <SafeAreaView className="flex-1">
      {/*
      // What goes here?

      - mini navigation bar at the top - says Prettyy at the top with actions on the left and right
      - Stories - but in the form of mini rectangles with some kind of cool animation/effect
      - Posts after stories

      */}
      <HomeNavigation>
        {/*
          This section is for something
        */}
        <PrettyyText className="text-2xl font-bold dark:text-white mx-auto p-12">Prettyy</PrettyyText>
      </HomeNavigation>
    </SafeAreaView>
  );
}