import React, { useState, useEffect } from 'react';
import { Text, TextInput, View, Button, SafeAreaView, Pressable, Platform } from "react-native";
import { useColorScheme } from 'nativewind';
import useApi from '../../api/useApi';
import PrettyyText from '../../extensions/PrettyyText';
import PrettyyTextInput from '../../extensions/PrettyyTextInput';
import {
  Search,
  AirplaneRotation
} from 'iconoir-react-native';
import * as Linking from 'expo-linking';
import { Image } from 'expo-image';
import { Skeleton } from 'moti/skeleton';
import { MotiView, ScrollView } from 'moti';
import Spin from '../misc/Spin';

// Debounce function to delay execution
const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export default function SearchComponent({ setRequestedUser = () => { } }) {
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchComplete, setSearchComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { get } = useApi();
  const { colorScheme } = useColorScheme();

  useEffect(() => {
    // If query is not empty, perform the search
    if (query.trim()) {
      performSearch();
    }
  }, [query]); // Effect runs when query changes

  // Debounced search function
  const performSearch = debounce(async () => {
    if (!query) return;

    setIsLoading(true);
    setError(null);

    try {
      const response = await get(`/v1/search/${query}`);

      if (response.users && Array.isArray(response.users)) {
        setSearchResults(response.users);
        setSearchComplete(true);
      } else {
        setSearchResults([]);
        setSearchComplete(true);
        if (!response.users) {
          setError("No users found.");
        }
      }
    } catch (e) {
      setError(e.message);
      setSearchResults([]);
    } finally {
      setIsLoading(false);
    }
  }, 500); // Delay of 500ms

  return (
    <SafeAreaView className="flex-1">
      <View className="flex-1 justify-center items-center p-1">
        <View className="flex flex-row w-full h-16 mb-4">
          <PrettyyTextInput
            className="flex-grow p-4 border-2 border-neutral-200/50 dark:border-neutral-50 h-full rounded-l-2xl bg-white dark:bg-neutral-50/5 dark:text-white"
            placeholder="Type your search here..."
            onChangeText={setQuery}
            value={query}
            returnKeyType="search"
            clearButtonMode="while-editing"
            placeholderTextColor={colorScheme === 'dark' ? 'white' : 'black'}
          />
          <Pressable
            className="p-2 bg-neutral-950 dark:bg-neutral-50 items-center justify-center aspect-square rounded-r-2xl border-y-2 border-neutral-100/95 dark:border-neutral-200/0 border-r-2"
            onPress={() => performSearch()}
          >
            <Search
              width={24}
              height={24}
              color={colorScheme === 'dark' ? 'black' : 'white'}
            />
          </Pressable>
        </View>

        {
          isLoading ? (
            <>
              {Array.from(Array(4).keys()).map((_, index) => (
                <View className="flex flex-row items-center justify-start w-full p-2 border-2 border-neutral-200/50 dark:border-neutral-800 rounded-2xl bg-white dark:bg-neutral-950 my-1">
                  <Skeleton.Group
                    key={index}
                    colorMode={colorScheme}
                    className=""
                  >
                    <View className="flex flex-row items-center p-4 rounded-2xl flex-1">
                      <MotiView
                        className="rounded-full overflow-hidden border-2"
                        animate={{
                          borderColor: colorScheme === 'dark' ? (
                            'rgba(64, 64, 64, 1)'
                          ) : (
                            'rgba(229, 229, 229, 1)'
                          ),
                        }}
                        transition={{ type: 'timing', duration: 300 }}
                      >
                        <Skeleton
                          width={48}
                          height={48}
                          colorMode={colorScheme}
                          className="rounded-full overflow-hidden border-2"
                        />
                      </MotiView>
                      <View className="flex flex-col ml-4">
                        <View>
                          <Skeleton
                            width={120}
                            height={28}
                            colorMode={colorScheme}
                            className="rounded-lg"
                          />
                        </View>
                        <View className="mt-0.5">
                          <Skeleton
                            width={70}
                            height={20}
                            colorMode={colorScheme}
                            className="rounded-lg"
                          />
                        </View>
                      </View>
                    </View>
                  </Skeleton.Group>
                </View>
              ))}
            </>
          ) : error ? (
            <View className="flex flex-col items-center justify-center">
              <PrettyyText className="text-lg font-semibold text-neutral-800 dark:text-neutral-200">
                {error}
              </PrettyyText>
            </View>
          ) : (
            // check if searchResults is empty
            (searchResults.length === 0 && searchComplete && query !== '') ? (
              <View className="flex flex-col items-center justify-center">
                <PrettyyText className="text-lg font-semibold text-neutral-800 dark:text-neutral-200">
                  Uh oh, nothing found!
                </PrettyyText>
              </View>
            ) : (
              <>
                <ScrollView className="flex-1 w-full">
                  {searchResults.map((user, index) => (
                    <Pressable
                      onPress={() => (Platform.OS === 'web' ? (Linking.openURL(`/@${user.username}`).catch(err => (err))) : setRequestedUser(user.username))}
                      key={index}
                      className="flex flex-row items-center justify-start w-full p-2 border-2 border-neutral-200/50 dark:border-neutral-800 rounded-2xl bg-white dark:bg-neutral-950 my-1"
                    >
                      <View className="flex flex-row items-center p-4 rounded-2xl flex-1">
                        <MotiView
                          className="rounded-full overflow-hidden border-2"
                          animate={{
                            borderColor: colorScheme === 'dark' ? (
                              'rgba(64, 64, 64, 1)'
                            ) : (
                              'rgba(229, 229, 229, 1)'
                            ),
                          }}
                          transition={{ type: 'timing', duration: 300 }}
                        >
                          <Image
                            contentFit="cover"
                            className="w-12 h-12 lg:w-24 lg:h-24"
                            source={user?.avatar}
                          />
                        </MotiView>
                        <View className="flex flex-col ml-4">
                          <PrettyyText className="text-lg font-semibold text-neutral-800 dark:text-neutral-200">
                            {user.full_name || "Someone Prettyy"}
                          </PrettyyText>
                          <PrettyyText className="text-sm text-neutral-500 dark:text-neutral-400">
                            @{user.username}
                          </PrettyyText>
                        </View>
                      </View>
                    </Pressable>
                  ))
                  }
                </ScrollView>
              </>
            )
          )
        }
      </View>
    </SafeAreaView >
  );
}
