import { useState, useRef, useEffect } from 'react';
import { tokenCache } from '../auth/TokenCache';

const API_BASE_URL = __DEV__ ? 'http://localhost:5001' : 'https://api.prettyy.net';
const RETRY_LIMIT = 3;
const TIMEOUT = 10000;  // 10 seconds

const useApi = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const mountedRef = useRef(true);

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const fetchData = async (endpoint, options = {}, retries = RETRY_LIMIT) => {
        setLoading(true);
        try {
            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), TIMEOUT);

            const response = await fetch(`${API_BASE_URL}${endpoint}`, { ...options, signal: controller.signal });

            clearTimeout(timeoutId);

            if (!response.ok && !(response.status >= 400 && response.status < 500)) {
                throw new Error(result.message || `HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();

            if (mountedRef.current) {
                setData(result);
                setError(null);
            }

            return result;
        } catch (err) {
            if (retries) {
                return fetchData(endpoint, options, retries - 1);
            }
            if (mountedRef.current) {
                setError(err);
            }
        } finally {
            if (mountedRef.current) {
                setLoading(false);
            }
        }
    };

    const get = async (endpoint) => {
        const token = await tokenCache.getToken('prettyyToken');

        let headers = {};
        
        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }

        const options = {
            method: 'GET',
            headers: headers,
        };
        
        return fetchData(endpoint, options);
    };

    const post = async (endpoint, body = {}) => {
        const token = await tokenCache.getToken('prettyyToken');
        let headers = {
            'Content-Type': 'application/json',
        };
        if (token) {
            body.prettyyToken = token;
            headers.Authorization = `Bearer ${token}`;
        }
        const options = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };
        return fetchData(endpoint, options);
    };

    // When data needs to be sent partially, use PATCH
    const patch = async (endpoint, body) => {
        const token = await tokenCache.getToken('prettyyToken');
        let headers = {
            'Content-Type': 'application/json',
        };
        if (token) {
            body.prettyyToken = token;
            headers.Authorization = `Bearer ${token}`;
        }
        const options = {
            method: 'PATCH',
            headers: headers,
            body: JSON.stringify(body),
        };
        return fetchData(endpoint, options);
    };

    // When data needs to be send completely, use PUT
    const put = async (endpoint, body) => {
        const token = await tokenCache.getToken('prettyyToken');
        let headers = {
            'Content-Type': 'application/json',
        };
        if (token) {
            body.prettyyToken = token;
            headers.Authorization = `Bearer ${token}`;
        }
        const options = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };
        return fetchData(endpoint, options);
    };

    const del = (endpoint) => {
        const options = {
            method: 'DELETE',
        };
        return fetchData(endpoint, options);
    };

    return {
        data,
        error,
        loading,
        get,
        post,
        patch,
        put,
        del,
    };
};

export default useApi;
