import React, { createContext, useContext, useState, useEffect } from 'react';
import useApi from "../api/useApi";
import { tokenCache } from './TokenCache';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isSignedIn, setIsSignedIn] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const { post } = useApi();

    // use the tokenCache to get the `prettyyToken`
    // and set the isSignedIn state to true if the token is valid

    useEffect(() => {
        async function checkToken() {
            const token = await tokenCache.getToken('prettyyToken');
            if (!token) {
                setIsSignedIn(false);
                setIsLoading(false);
                return;
            }

            const endpoint = "/v1/users/verify";
            const response = await post(endpoint);

            if (!response || response.error) {
                setIsSignedIn(false);
                setIsLoading(false);
                return;
            }

            await tokenCache.saveToken('username', response.user_uid);
            setIsLoading(false);
        }

        checkToken();
    }, [tokenCache]);

    const signOut = async () => {
        await tokenCache.removeToken('prettyyToken');
        //console.log('Signed out');
        setIsSignedIn(false);
    };

    // FIXME: remove this function
    const debugSignIn = async () => {
        await tokenCache.saveToken('prettyyToken', '12345');
        //console.log('Signed in with debug token');
        setIsSignedIn(true);
    }

    return (
        <AuthContext.Provider value={{ isSignedIn, setIsSignedIn, signOut, debugSignIn }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
};

export const IsSignedIn = ({ children }) => {
    const { isSignedIn } = useAuth();

    return (
        <>
            {isSignedIn && children}
        </>
    );
}

export const IsSignedOut = ({ children }) => {
    const { isSignedIn } = useAuth();

    return (
        <>
            {!isSignedIn && children}
        </>
    );
}