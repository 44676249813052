// PrettyyTextInput.jsx
import React from 'react';
import { StyleSheet, TextInput } from 'react-native';

const styles = StyleSheet.create({
    defaultStyle: {
        fontFamily: 'dmsans',
    },
});

const PrettyyTextInput = function ({
    children,
    className = '',
    style = {},
    ...props
}) {
    return (
        <TextInput
            className={`${className}`}
            style={[styles.defaultStyle, style]}
            {...props}
        >
            {children}
        </TextInput>
    );
}

export default PrettyyTextInput;
